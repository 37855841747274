import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./Map.module.css";
import { Icon } from "leaflet";

const position = [54.1769, 18.6904];

const customIcon = new Icon({
  iconUrl:
    "https://upload.wikimedia.org/wikipedia/commons/9/93/Map_marker_font_awesome.svg",
  iconSize: [32, 32], //size icon
  attribution:
    "Font Awesome by Dave Gandy - https://fortawesome.github.com/Font-Awesome, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
});

function Map() {
  return (
    <div className={styles.mapContainer}>
      <MapContainer center={position} zoom={15}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={customIcon}>
          <Tooltip
            className={styles.tooltip}
            direction="bottom"
            offset={[0, -70]}
            opacity={1}
            permanent
            interactive
          >
            ul. Jana Pawła II 23, 83-032 Pszczółki
            <a href="https://goo.gl/maps/BHYa7GuVzDFRb5GB8" target="blank">
              <button className={styles.address}>dojazd</button>
            </a>
          </Tooltip>
        </Marker>
      </MapContainer>
      <h6 className={styles.mapHeader}>gdzie nas znajdziesz</h6>
    </div>
  );
}

export default Map;
