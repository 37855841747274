import { BsEnvelope, BsTelephone, BsGeoAlt } from "react-icons/bs";
import { IconContext } from "react-icons";
import styles from "./Header.module.css";
import { motion } from "framer-motion";
import logo from "../assets/logo.jpg";
import { FaBars } from "react-icons/fa";
import { useState } from "react";

function Header() {
  const [showInfo, setShowInfo] = useState(false);

  const toggleInfo = () => {

    setShowInfo(!showInfo);
  };

  return (
    <header className={styles.header}>
      <div className={styles.contactInfo}>
        <IconContext.Provider value={{ className: "icon-small" }}>
          <div className={`${styles.test} ${showInfo ? styles.phone : ""}`}>
            <div className={styles.contactItem}>
              <BsTelephone />

              <motion.a href="tel:695-975-450" whileHover={{ scale: 1.01 }}>
                Tel: 695-975-450
              </motion.a>
            </div>
            <div className={styles.contactItem}>
              <BsEnvelope />
              <motion.a
                href="mailto:slawomir3003@wp.pl"
                whileHover={{ scale: 1.01 }}
              >
                kontakt@osk-abas.pl
              </motion.a>
            </div>
            <div className={styles.contactItem}>
              <BsGeoAlt />
              <span>ul. Jana Pawła II 23, 83-032 Pszczółki</span>
            </div>
          </div>
        </IconContext.Provider>
      </div>
      <div className={styles.containerLogo}>
        <div>
          <button onClick={toggleInfo} className={styles.toggleButton}>
            <FaBars />
          </button>
        </div>

        <div className={styles.logo}>
          <img src={logo} alt="logo firmy ABAS" />
        </div>
      </div>
    </header>
  );
}

export default Header;
