import styles from "./UndrawImage.module.css";
import { BsCheck } from "react-icons/bs";
import Button from "./Button";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

// animations
const slideFromRight = {
  hidden: {
    x: "300px",
  },
  visible: {
    x: 0,
    transition: {
      type: "spring",
      duration: 0.85,
      delay: 0.5,
    },
  },
};

const showCard = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.85,
      delay: 0.1,
    },
  },
};

function UndrawImage({ src, alt }) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.05,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className={styles.container} id="offer">
      <motion.div
        className={styles.card}
        variants={showCard}
        animate={controls}
        initial="hidden"
        ref={ref}
      >
        <h5 className={styles.heading}>Kurs samochód osobowy</h5>
        <p className={styles.category}>Kategoria B</p>
        <p className={styles.margin}>
          <span className={styles.price}>2 500</span>zł
        </p>
        <ul className={styles.list}>
          <li className={styles.flex}>
            <BsCheck className={styles.icon} />
            30h szkolenia praktycznego
          </li>
          <li className={styles.flex}>
            <BsCheck className={styles.icon} />
            Potrzebne materiały
          </li>
          <li className={styles.flex}>
            <BsCheck className={styles.icon} />
            Nauka online
          </li>
          <li className={styles.flex}>
            <BsCheck className={styles.icon} />
            Możliwość opłaty ratalnej
          </li>
        </ul>
        <Button className={styles.button}>
          <a className={styles.link} href="mailto:slawomir3003@wp.pl">
            umów się na wizytę
          </a>
        </Button>
      </motion.div>
      <div className={styles.imageContainer}>
        <motion.p
          variants={slideFromRight}
          animate={controls}
          initial="hidden"
          ref={ref}
          className={styles.slogan}
        >
          Ruszmy razem w drogę
        </motion.p>
        <img src={src} alt={alt} className={styles.img} />
      </div>
    </div>
  );
}

export default UndrawImage;
