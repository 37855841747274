import { BiLogoFacebookSquare } from "react-icons/bi";
import style from "./Footer.module.css";

function Footer() {
  return (
    <footer>
      <p>
        Znajdź nas na Facebook, sprawdź aktualne informacje i podziel się
        opinią!
      </p>
      <div className={style.fbContainer}>
        <a
          href="https://www.facebook.com/people/Ośrodek-Szkolenia-Kierowców-ABAS/100063818170173/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BiLogoFacebookSquare className={style.fb} />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
