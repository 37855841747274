import styles from "./ContactUs.module.css";
import background from "../assets/background_contact_us.jpg";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";

function ContactUs() {
  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className={styles.containerImg}
    >
      <h3 className={styles.heading}>Zapisz się już teraz!</h3>
      <p className={styles.text}>Zadzwoń i umów się na kurs</p>
      <div className={styles.phone}>
        <BsFillTelephoneOutboundFill className={styles.icon} />
        <a href="tel:695-975-450" id={styles.id}>
          695-975-450
        </a>
      </div>
    </div>
  );
}

export default ContactUs;
