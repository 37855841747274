import background from "../assets/background1.jpg";
import styles from "./HeroSection.module.css";
import Button from "./Button";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

function HeroSection({ onClick }) {
  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className={styles.containerImg}
    >
      <div className={styles.heroText}>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 2 }}
          className={styles.companyName}
        >
          Ośrodek Szkolenia Kierowców
        </motion.h1>
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 2 }}
          className={styles.logoText}
        >
          ABAS
        </motion.h2>

        <Button className={styles.primary}>
          <Link to="offer" smooth={true} duration={500} className={styles.link}>
            SPRAWDŹ OFERTĘ
          </Link>
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
