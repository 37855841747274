import Modal from "react-modal";
import { createPortal } from "react-dom";
import friends from "../assets/friends.jpg";
import styles from "./ModalPopUp.module.css";

function ModalPopUp({ isOpen, onRequestClose }) {
  return createPortal(
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      contentLabel="Oferta 2h jazdy za darmo jeśli przyprowadzisz koleżankę lub kolegę"
      className={styles.modal}
      style={{
        overlay: {
          zIndex: 99999999,
        },
        content: {
          padding: 0,
          overflow: "hidden",
          maxWidth: "500px",
          margin: "0 auto",
        },
      }}
    >
      <div
        className={styles.imgContainer}
        style={{
          background: `url(${friends}) no-repeat center center`,
        }}
      >
        <button
          className={styles.closeButton}
          onClick={onRequestClose}
          aria-label="Close Modal"
        >
          X
        </button>
        <div className={styles.offer}>
          <div>
            <span className={styles.text}>
              przyprowadź koleżankę lub kolegę, a otrzymasz 2h jazdy za darmo!
            </span>
          </div>
        </div>
      </div>
    </Modal>,
    document.getElementById("portal")
  );
}

export default ModalPopUp;
