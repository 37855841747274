const BUTTON_STYLE = {
  padding: "2rem 4rem",
  borderRadius: "500px",
  fontFamily: "inherit",
  letterSpacing: "1px",
  border: "none",
  cursor: "pointer",
  backgroundColor: "var(--color-primary)",
  zIndex: "2",
};

function Button({ children, onClick, className }) {
  return (
    <button onClick={onClick} style={BUTTON_STYLE} className={`${className}`}>
      {children}
    </button>
  );
}

export default Button;
