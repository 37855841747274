// wrapper for sections which need a default padding

import { useEffect, useState } from "react";

function Wrapper({ children }) {
  const [padding, setPadding] = useState("3.2rem");

  // default padding 3.2rem, for <=800px 1rem
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setPadding("1rem");
      } else {
        setPadding("3.2rem");
      }
    };

    //initial check
    handleResize();

    //listen for width changes
    window.addEventListener("resize", handleResize);

    //clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <div style={{ padding }}>{children}</div>;
}

export default Wrapper;
