import Wrapper from "./Wrapper";
import styles from "./WhyUs.module.css";
import {
  BsBuildings,
  BsHouses,
  BsWallet2,
  BsBook,
  BsSignTurnLeft,
  BsFillCarFrontFill,
} from "react-icons/bs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

/* ------------------animation----------------- */
const variants = {
  visible: (i) => ({
    opacity: 1,
    scale: 1,
    transition: {
      delay: i * 0.3,
    },
  }),
  hidden: { opacity: 0, scale: 0 },
};

function WhyUs() {
  const items = [
    "Możliwość odbioru kursanta spod domu lub innego wybranego miejsca",
    "Gwarancja najniższej ceny w okolicy i możliwa płatność w ratach",
    "Teoria on-line. Możliwość rozpoczęcia nauki w dowolnym terminie",
    "18 lat doświadczenia instruktora jazdy w zawodzie",
    "Profesjonalny plac manewrowy (jedyny w okolicy)",
    "Posiadamy samochody takie same jak na egzaminie państwowym",
  ];

  const [ref, inView] = useInView();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  return (
    <Wrapper>
      <h3 className={styles.header}>Co nas wyróżnia?</h3>
      <div className={styles.container} ref={ref}>
        {items.map((item, i) => (
          <motion.div
            key={i}
            custom={i}
            animate={isVisible ? "visible" : "hidden"}
            variants={variants}
            initial="hidden"
            className={styles.center}
          >
            {i === 0 && <BsHouses className={styles.icon} />}
            {i === 1 && <BsWallet2 className={styles.icon} />}
            {i === 2 && <BsBook className={styles.icon} />}
            {i === 3 && <BsBuildings className={styles.icon} />}
            {i === 4 && <BsSignTurnLeft className={styles.icon} />}
            {i === 5 && <BsFillCarFrontFill className={styles.icon} />}
            <p className={styles.paragraph}>{item}</p>
          </motion.div>
        ))}
      </div>
    </Wrapper>
  );
}

export default WhyUs;
