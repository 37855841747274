import { useEffect, useState } from "react";
import Cookie from "./componenets/Cookie";
import Header from "./componenets/Header";
import HeroSection from "./componenets/HeroSection";
import WhyUs from "./componenets/WhyUs";
import ContactUs from "./componenets/ContactUs";
import UndrawImage from "./componenets/UndrawImage";
import undrawIllustration from "./assets/toycar.svg";
import Map from "./componenets/Map";
import ModalPopUp from "./componenets/ModalPopUp";
import OnlineTests from "./componenets/OnlineTests";
import Footer from "./componenets/Footer";

function App() {
  const [isCookieAccepted, setIsCookieAccepted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  //cookie local storage
  useEffect(() => {
    const isAccepted = localStorage.getItem("cookieAccepted");

    if (isAccepted) {
      setIsCookieAccepted(true);
    }
  }, []);

  //modal PopUp auto display after delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setModalIsOpen(true);
    }, 18000);

    return () => clearTimeout(timer);
  }, []);

  //cookie handler
  const handleCookie = () => {
    localStorage.setItem("cookieAccepted", true);
    setIsCookieAccepted(true);
  };

  //modal Close PopUp handler
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      {!isCookieAccepted && <Cookie onClick={handleCookie} />}
      <Header />
      <HeroSection />
      <WhyUs />
      <ContactUs />
      <UndrawImage src={undrawIllustration} />
      <OnlineTests />
      <Map />
      <ModalPopUp onRequestClose={closeModal} isOpen={modalIsOpen} />
      <Footer />
    </>
  );
}

export default App;
