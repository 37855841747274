import style from "./OnlineTests.module.css";
import office from "../assets/office.jpg";

function OnlineTests() {
  return (
    <div>
      <div className={style.testImg}>
        <h3 className={style.testHeader}>Rozpocznij naukę zdalnie z domu!</h3>
        <p>Testy na prawo jazdy online</p>
        <div className={style.testGrid}>
          <figure className={style.imgContainer}>
            <img
              src={office}
              alt="person who writes a test"
              className={style.testPhoto}
            />
          </figure>
          <div className={style.paragraph}>
            <p>
              Nauka online na prawo jazdy to innowacyjny sposób zdobywania
              umiejętności prowadzenia pojazdu, który przynosi wiele korzyści.
              Przede wszystkim, taka forma nauki zapewnia elastyczność czasową i
              przestrzenną. Możesz uczyć się z dowolnego miejsca i o dowolnej
              porze, dostosowując naukę do swojego planu dnia.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnlineTests;
