import styles from "./Cookie.module.css";
import Button from "./Button";

function Cookie({ onClick }) {
  return (
    <div className={styles.container}>
      <p className={styles.cookieText}>
        Kontynuując korzystanie z naszej witryny, zgadzasz się na wykorzystanie
        plików cookie, które są niezbędne w celu prawidłowego działania strony.
      </p>
      <Button className={styles.cookieButton} onClick={onClick}>
        akceptuję
      </Button>
    </div>
  );
}

export default Cookie;
